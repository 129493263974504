@font-face {
    font-family: 'Effra';
    src: local('Effra'), url(./fonts/Effra.ttf) format('truetype');
}

@font-face {
    font-family: 'EffraBold';
    src: local('EffraBold'), url(./fonts/EffraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto.ttf) format('truetype');
}

@font-face {
    font-family: 'RobotoBold';
    src: local('RobotoBold'), url(./fonts/RobotoBold.ttf) format('truetype');
}

html {
    background-color: #F8F8F8;
    overflow-x: hidden;
}

body {
    font-family: 'Effra';
    padding-top: 40px;
    overflow-x: hidden;
}

.close-icon {
    position: absolute;
    right: 30px;
    top: 10px;
    cursor: pointer;
}

.no-border {
    border: none !important;
    padding-bottom: 0 !important;
}

.MuiFormControl-root .MuiFormHelperText-contained {
    margin-left: 0 ;
}
